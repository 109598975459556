import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
// Importing FontAwesome icons
// import { 
//  // RiAiGenerate2,
//    FaGem, 
//   // FaCloud, 
//   // FaAngular, 
//   // FaReact, 
//   // FaHtml5, 
//   // FaCss3Alt, 
//   // FaCuttlefish, 
//   // FaPython, 
//   // FaWindows, 
//   // FaGitAlt,
//   // FaDatabase,      // Added
//   // FaCalculator,    // Added
//   // FaTools          // Added
// } from 'react-icons/fa';

// Importing Simple Icons
// React Icons Library: https://react-icons.github.io/react-icons/search/#q=AngularJS

import { RiAiGenerate2 } from "react-icons/ri";
import { SiGooglegemini } from "react-icons/si";
import { VscAzure } from "react-icons/vsc";
import { RiAngularjsFill } from "react-icons/ri";
import { AiOutlineOpenAI } from 'react-icons/ai';
import { RiReactjsLine } from "react-icons/ri";
import { BsFiletypeHtml } from "react-icons/bs";
import { PiFileCss } from "react-icons/pi";
import { PiFileCSharpDuotone } from "react-icons/pi";
import { IoLogoJavascript } from "react-icons/io";
import { FaPython } from "react-icons/fa6";
import { FaWindows } from "react-icons/fa";
import { DiMsqlServer } from "react-icons/di";
import { FaDatabase } from "react-icons/fa";
import { BsDatabase } from "react-icons/bs";
import { SiSimpleanalytics } from "react-icons/si";
import { FaCalculator } from "react-icons/fa";
import { TbBrandAzure } from "react-icons/tb";
import { SiGooglecloud } from "react-icons/si";
import { IoLogoFirebase } from "react-icons/io5";
import { SiOracle } from "react-icons/si";
import { IoLogoBitbucket } from "react-icons/io";
import { FaGitAlt } from "react-icons/fa";
import { SiMysql } from "react-icons/si";
import { BiLogoMongodb } from "react-icons/bi";
import { SiSqlite } from "react-icons/si";
import { VscAzureDevops } from "react-icons/vsc";
import { SiYaml } from "react-icons/si";
import { FaWix } from "react-icons/fa";
import { SiConfluence } from "react-icons/si";
import { SiJira } from "react-icons/si";
// Importing Simple Icons
import Timeline from '../components/Timeline';
import DailyActivityBar from '../components/DailyActivityBar';
import azureFundamentalsImg from '../assets/AzureFundamentals.png';

const Home = () => {
  const projects = [
    {
      title: "Generative AI-Powered Solution - Agents",
      description:
       "An innovative platform leveraging Generative AI to power intelligent agents for real-time content generation and decision-making, built using React and Node.js.",
      viewLink: "/projects/ai-solution",
      demoLink: "https://demo.ai-solution.com"
    },
    // {
    //   title: "Dynamic SaaS Application",
    //   description:
    //     "An enterprise-grade SaaS product built with Python and Django, designed for scalable, full-stack deployment.",
    //   viewLink: "/projects/saas-application",
    //   demoLink: "https://demo.saas-application.com"
    // },
    // {
    //   title: "E-commerce Recommendation Engine",
    //   description:
    //     "A recommendation system powered by machine learning, built to enhance customer engagement for e-commerce.",
    //   viewLink: "/projects/recommendation-engine",
    //   demoLink: "https://demo.recommendation-engine.com"
    // },
    // {
    //   title: "Real-Time Collaboration Tool",
    //   description:
    //     "A collaborative platform developed with WebSockets, enabling real-time updates and team collaboration.",
    //   viewLink: "/projects/collaboration-tool",
    //   demoLink: "https://demo.collaboration-tool.com"
    // },
    // {
    //   title: "Interactive Data Visualization Platform",
    //   description:
    //     "A platform utilizing D3.js and React for creating interactive and insightful data visualizations.",
    //   viewLink: "/projects/data-visualization",
    //   demoLink: "https://demo.data-visualization.com"
    // }
  ];

  
  const skills = [
    {
      name: "Generative AI",
      description: "Proficient in Generative AI for creating intelligent and adaptive systems.",
      icon: <RiAiGenerate2 />
    },
    {
      name: "OpenAI",
      description: "Experienced with OpenAI technologies for developing advanced AI solutions.",
      icon: <AiOutlineOpenAI  />
     },
    {
      name: "Gemini",
      description: "Skilled in Gemini for data integration and analysis.",
      icon: <SiGooglegemini />
     },
    {
      name: "Azure ML",
      description: "Expertise in Azure Machine Learning for building and deploying machine learning models.",
      icon: <VscAzure />
    },
    {
      name: "AngularJS",
      description: "Proficient in AngularJS for building dynamic web applications.",
      icon: <RiAngularjsFill />
    },
    {
      name: "React JS",
      description: "Expertise in building dynamic user interfaces with React JS.",
      icon: <RiReactjsLine />
    },
    {
      name: "CSHTML",
      description: "Skilled in CSHTML for creating server-rendered web pages.",
      icon: <BsFiletypeHtml />
    },
    {
      name: "CSS",
      description: "Experienced in styling web applications using CSS.",
      icon: <PiFileCss />
    },
   {
      name: "C#",
      description: "Proficient in C# for building robust backend systems.",
      icon: <PiFileCSharpDuotone />
    },
    {
      name: "JavaScript",
      description: "Skilled in JavaScript for developing interactive web applications.",
      icon: <IoLogoJavascript />
    },
    {
      name: "Python",
      description: "Experienced with Python for data analysis and automation.",
      icon: <FaPython />
    },
    {
      name: "WPF",
      description: "Proficient in developing Windows-based applications.",
      icon: <FaWindows />
    },
    {
      name: "MSSQL",
      description: "Experienced with Microsoft SQL Server for managing and querying databases.",
      icon: <DiMsqlServer />
    },
    {
      name: "SSIS",
      description: "Skilled in SQL Server Integration Services for data integration solutions.",
      icon: <FaDatabase />
    },
    {
      name: "SSRS",
      description: "Experienced with SQL Server Reporting Services for creating and managing reports.",
      icon: <BsDatabase />
    },
    {
      name: "Power BI",
      description: "Proficient in Power BI for data visualization and business intelligence.",
      icon: <SiSimpleanalytics />
    },
    {
      name: "DAX",
      description: "Skilled in DAX for advanced data modeling and analysis in Power BI.",
      icon: <FaCalculator />
    },
    {
      name: "Azure",
      description: "Knowledgeable in deploying and managing applications on Microsoft Azure.",
      icon: <TbBrandAzure  />
    },
    {
      name: "Google Cloud",
      description: "Experienced with Google Cloud Platform for scalable cloud solutions.",
      icon: <SiGooglecloud />
    },
    {
      name: "Firebase",
      description: "Skilled in using Firebase for backend services and real-time databases.",
      icon: <IoLogoFirebase />
    },
    {
      name: "Oracle",
      description: "Experienced with Oracle databases for enterprise-level data management.",
      icon: <SiOracle />
    },
    {
      name: "MySQL",
      description: "Proficient in MySQL for designing and managing relational databases.",
      icon: <SiMysql />
    },
    {
      name: "MongoDB",
      description: "Skilled in MongoDB for handling NoSQL database solutions.",
      icon: <BiLogoMongodb  />
    },
    {
      name: "SQLite",
      description: "Experienced with SQLite for lightweight database applications.",
      icon: <SiSqlite />
    },
    {
      name: "Azure DevOps",
      description: "Proficient in Azure DevOps for continuous integration and deployment.",
      icon: <VscAzureDevops />
    },
    {
      name: "YAML",
      description: "Skilled in YAML for configuration management and automation scripts.",
      icon: <SiYaml />
    },
    {
      name: "WiX",
      description: "Experienced with WiX for creating Windows installation packages.",
      icon: <FaWix />
    },
    {
      name: "Confluence",
      description: "Proficient in using Confluence for team collaboration and documentation.",
      icon: <SiConfluence />
    },
    {
      name: "Jira",
      description: "Skilled in Jira for project management and issue tracking.",
      icon: <SiJira />
    },
   {
      name: "Git",
      description: "Experienced with Git for version control and collaborative development.",
      icon: <FaGitAlt />
    },
    {
      name: "TFS",
      description: "Proficient in Team Foundation Server for source control and project management.",
      icon: <TbBrandAzure />
    },
    {
      name: "Bitbucket",
      description: "Skilled in Bitbucket for Git repository management and collaboration.",
      icon: <IoLogoBitbucket />
    }
  ];
  
  
  // Example certifications data with placeholder image
  const certifications = [
    {
      title: "Microsoft Azure Fundamentals",
      description:
       "Validates foundational knowledge of cloud concepts, Azure services, workloads, security, and pricing, essential for starting a career in cloud computing.",
      image: azureFundamentalsImg ,//via.placeholder.com/300x200.png?text=Certification",
      link: "https://learn.microsoft.com/en-us/users/adityadharoorkamsali-8296/credentials/c2178c598a88eaab" // or your actual certification link
    }
    

    // Will add these in the future
    // ,
    // {
    //   title: "Microsoft Learn Transcripts",
    //   description:
    //     "Display of Upto Date Learning completed on Microsoft Learn",
    //   image: azureFundamentalsImg,
    //   link: "https://learn.microsoft.com/en-us/users/adityadharoorkamsali-8296/transcript/7k260i9rk9zyz1x" // or your actual certification link
    // }
    // Add more certifications here if desired
  ];

  return (
    <main className="home">
      {/* Hero Section */}
      <section className="home__hero">
        <h1 className="home__title">Hi, I'm Aditya Dharoor Kamsali (DK)</h1>
        <p className="home__subtitle">
        Senior Software Engineer with expertise in full-stack development, cloud technologies, rapid prototyping and generative AI solutions.
        </p>
      </section>

      {/* Timeline and Daily Activity Section */}
      <section className="home__timeline-activity">
        <div className="home__graph-title">Professional Timeline</div>
        <Timeline />
        <div className="home__graph-title">Typical Day</div>
        <DailyActivityBar />
      </section>

      {/* About Section */}
      <section className="home__about" id="about">
        <h2 className="home__section-title">About Me</h2>
        <p className="home__about-text">
        With a Bachelor’s in Computer Science, Master’s in IT and over a decade of experience in the tech industry.
        I specialize in building fast, responsive applications with a keen focus on both frontend and backend technologies.
        I’m passionate about leveraging Generative AI to enhance user experiences and accelerate development cycles, 
        enabling swift delivery of functional prototypes. Let’s bring ideas to life.
        </p>
        
        <Link to="/about" className="home__project-link">Learn More About Me</Link>
      </section>


       
      {/* Featured Projects Section
      <section className="home__projects" id="projects">
        <h2 className="home__section-title">Featured Projects</h2>
        <div className="home__project-grid">
          {projects.map((project, index) => (
            <div key={index} className="home__project-card">
              <h3 className="home__project-title">{project.title}</h3>
              <p className="home__project-description">{project.description}</p>
              <div className="home__project-buttons">
                <Link to={project.viewLink} className="home__project-link">View Project</Link>
                <a
                  href={project.demoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="home__project-link"
                >
                  Live Demo
                </a>
              </div>
            </div>
          ))}
        </div>
        <Link to="/projects" className="home__link">See all projects</Link>
      </section> */}

   {/* Skills Section */}
   {/* Skills Section */}
   <h2 className="home__section-title" style={{ textAlign: 'center' }}>Skills</h2>


  <section className="home__skills" id="skills">
  <div className="home__skills-grid">
  {skills.map((skill, index) => (
    <div key={index} className="skill-item">
      <span className="skill-icon" data-tooltip={skill.description}>
        {skill.icon}
      </span>
      <span className="skill-name" data-tooltip={skill.description}>
        {skill.name}
      </span>
    </div>
  ))}
  </div>

      </section>



     {/* Certifications Section */}
            <section className="home__projects" id="certifications">
        <h2 className="home__section-title">Certifications</h2>
        <div className="home__project-grid">
          {certifications.map((cert, index) => (
            <div key={index} className="home__project-card">
              <img
                src={cert.image}
                alt={cert.title}
                className="home__project-image"
                style={{ width: '100%', height: 'auto' }}
              />
              <h3 className="home__project-title">{cert.title}</h3>
              <p className="home__project-description">{cert.description}</p>
              <div className="home__project-buttons">
                <a
                  href={cert.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="home__project-link"
                >
                  View Certification
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Contact Section */}
      <section className="home__contact">
        <h2 className="home__section-title">Microsoft Learn Transcript</h2>
        <p className="home__contact-text">
        This will provide you with an overview of the modules I’ve completed on Microsoft Learn and showcase my enthusiasm for ongoing learning.
        </p>


        <div className="home__project-buttons">
                <a
                  href="https://learn.microsoft.com/en-us/users/adityadharoorkamsali-8296/transcript/7k260i9rk9zyz1x"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="home__project-link"
                >
                  View Microsoft Transcript
                </a>
              </div>
      </section>

      {/* Contact Section */}
      <section className="home__contact">
        <h2 className="home__section-title">Let's Connect</h2>
        <p className="home__contact-text">
          Whether you want to collaborate on a project, explore Generative AI, or discuss rapid prototyping,
          feel free to reach out.
        </p>
        <Link to="/contact" className="home__cta home__cta--contact">Get in Touch</Link>
      </section>
    </main>
  );
};

export default Home;
