import React, { useEffect, useState } from "react";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { db, auth } from "../firebase";
import "./ProtectedPage.css";

const ProtectedPage = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueValues, setUniqueValues] = useState({});
  const [filters, setFilters] = useState({
    operatingSystem: [],
    city: [],
    region: [],
    country: [],
    timestamp: [],
  });
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check if the user is authenticated
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        window.location.href = "/login"; // Redirect unauthenticated users
      }
    });

    return () => unsubscribe();
  }, []);

  // Extract unique values from the data for filter options
  const extractUniqueValues = (data) => {
    return {
      operatingSystem: [...new Set(data.map((item) => item.operatingSystem))],
      city: [...new Set(data.map((item) => item.city))],
      region: [...new Set(data.map((item) => item.region))],
      country: [...new Set(data.map((item) => item.country))],
      timestamp: [
        ...new Set(
          data
            .filter((item) => item.timestamp)
            .map((item) => new Date(item.timestamp).toISOString().split("T")[0])
        ),
      ],
    };
  };

  // Fetch data from Firestore and initialize unique values
  useEffect(() => {
    if (!isAuthenticated) return; // Ensure only authenticated users can fetch data

    const fetchData = async () => {
      try {
        const locationLogsQuery = query(
          collection(db, "locationLogs"),
          orderBy("timestamp", "desc"),
          limit(100)
        );

        // Fetch only if authenticated (rules prevent read for unauthenticated users)
        const querySnapshot = await getDocs(locationLogsQuery);
        const documents = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            operatingSystem: data.operatingSystem || "N/A",
            city: data.city || "N/A",
            region: data.region || "N/A",
            country: data.country || "N/A",
            timestamp: data.timestamp?.toDate
              ? data.timestamp.toDate()
              : data.timestamp || null,
          };
        });

        setData(documents);
        setFilteredData(documents);
        setUniqueValues(extractUniqueValues(documents));
      } catch (error) {
        console.error("Error fetching Firestore data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload(); // Refresh the page or redirect to login
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Handle checkbox toggle for a filter category
  const handleCheckboxChange = (category, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[category].includes(value)) {
        updatedFilters[category] = updatedFilters[category].filter(
          (item) => item !== value
        );
      } else {
        updatedFilters[category].push(value);
      }
      return updatedFilters;
    });
  };

  // Apply filters to the data
  useEffect(() => {
    const applyFilters = () => {
      const filtered = data.filter((item) => {
        const itemDate = item.timestamp
          ? new Date(item.timestamp).toISOString().split("T")[0]
          : "N/A";

        return (
          (filters.operatingSystem.length === 0 ||
            filters.operatingSystem.includes(item.operatingSystem)) &&
          (filters.city.length === 0 || filters.city.includes(item.city)) &&
          (filters.region.length === 0 || filters.region.includes(item.region)) &&
          (filters.country.length === 0 ||
            filters.country.includes(item.country)) &&
          (filters.timestamp.length === 0 ||
            filters.timestamp.includes(itemDate))
        );
      });
      setFilteredData(filtered);
    };

    applyFilters();
  }, [filters, data]);

  if (!isAuthenticated) {
    return <h2>Authenticating...</h2>;
  }

  if (loading) {
    return <h2>Loading data...</h2>;
  }

  // Dropdown Filter Component
  const DropdownFilter = ({ category, values }) => {
    return (
      <div className="dropdown">
        <button className="dropbtn">Filter</button>
        <div className="dropdown-content">
          {values.map((value) => (
            <label key={value}>
              <input
                type="checkbox"
                checked={filters[category].includes(value)}
                onChange={() => handleCheckboxChange(category, value)}
              />
              {value}
            </label>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="protected-page">
      <div className="header">
        <h2>Fetched Data from Firestore (locationLogs):</h2>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <table className="data-table">
        <thead>
          <tr>
            <th>
              Operating System
              <DropdownFilter
                category="operatingSystem"
                values={uniqueValues.operatingSystem || []}
              />
            </th>
            <th>
              City
              <DropdownFilter category="city" values={uniqueValues.city || []} />
            </th>
            <th>
              Region
              <DropdownFilter
                category="region"
                values={uniqueValues.region || []}
              />
            </th>
            <th>
              Country
              <DropdownFilter
                category="country"
                values={uniqueValues.country || []}
              />
            </th>
            <th>
              Timestamp (Dates)
              <DropdownFilter
                category="timestamp"
                values={uniqueValues.timestamp || []}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id}>
              <td>{item.operatingSystem}</td>
              <td>{item.city}</td>
              <td>{item.region}</td>
              <td>{item.country}</td>
              <td>
                {item.timestamp
                  ? new Date(item.timestamp).toString()
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProtectedPage;
