import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HighlightedName from './HighlightedName';
import axios from 'axios';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure you have Firebase set up and configured
import { isMobile, isTablet, isDesktop, osName, browserName } from 'react-device-detect';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    city: '',
    region: '',
    country: '',
    ip: '',
    error: null,
  });

  const [lastLoggedIp, setLastLoggedIp] = useState(null);

  useEffect(() => {
    const fetchLocationDetails = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const { city, region, country, ip } = response.data;
        setLocation((prev) => ({ ...prev, city, region, country, ip, error: null }));
      } catch (error) {
        setLocation((prev) => ({ ...prev, error: 'Unable to fetch location details' }));
      }
    };

    fetchLocationDetails();
  }, []);

  useEffect(() => {
    const logLocationDetails = async () => {
      if (location.ip && location.ip !== lastLoggedIp) {
        const logData = {
          device: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : 'Unknown',
          operatingSystem: osName,
          browser: browserName,
          ip: location.ip,
          city: location.city || null,
          region: location.region || null,
          country: location.country || null,
          latitude: location.latitude ?? null,
          longitude: location.longitude ?? null,
          timestamp: new Date().toISOString(),
        };
        try {
          const logsCollection = collection(db, 'locationLogs');
          await addDoc(logsCollection, logData);
          setLastLoggedIp(location.ip);
        } catch (error) {
          console.error('Error logging location details:', error);
        }
      }
    };

    logLocationDetails();
  }, [location, lastLoggedIp]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header__container">
        <Link to="/" className="header__logo-link">
          <HighlightedName />
        </Link>

        <button className="header__hamburger" onClick={toggleMenu}>
          ☰
        </button>

        <nav className={`header__nav ${isMenuOpen ? 'header__nav--open' : ''}`}>
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <Link to="/" className="header__nav-link" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li className="header__nav-item">
              <Link to="/about" className="header__nav-link" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li className="header__nav-item">
              <Link to="/projects" className="header__nav-link" onClick={toggleMenu}>
                Projects
              </Link>
            </li>
            <li className="header__nav-item">
              <Link to="/contact" className="header__nav-link" onClick={toggleMenu}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
