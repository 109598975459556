// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        {/* Brand Name */}
        <div className="footer__brand">
          <Link to="/" className="footer__brand-link">Aditya Dharoor Kamsali (DK)</Link>
        </div>

        {/* Social Links */}
        <div className="footer__social">
          <a href="https://github.com/adityadharoor" target="_blank" rel="noopener noreferrer" className="footer__social-link">
            GitHub
          </a>
          <a href="https://linkedin.com/in/adityadharoor" target="_blank" rel="noopener noreferrer" className="footer__social-link">
            LinkedIn
          </a>
        </div>

        {/* Copyright */}
        <div className="footer__info">
          <p>© {new Date().getFullYear()} Aditya Dharoor Kamsali. All rights reserved.</p>
          <p>Built with passion by DK</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
