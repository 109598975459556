// Projects.js
import React from 'react';
import './Projects.css';

// Sample project data
const projectData = [
  {
    id: 1,
    title: 'Generative AI-Powered Solution',
    description: 'A firebase based real estate website , that helps with searching Real-Estate listings  similar to a realtime realtor',
    technologies: ['React', 'Node.js', 'FireStore','Firebase Functions','Gemini API'],
    link: '/projects/ecommerce',
   }
  //  ,
  // {
  //   id: 2,
  //   title: 'AI Agents',
  //   description: 'A scalable SaaS platform built with Python and Django, providing real-time analytics for enterprises.',
  //   technologies: ['Python', 'Django', 'PostgreSQL', 'Docker'],
  //   link: '/projects/saas-analytics',
  // },
  // {
  //   id: 3,
  //   title: 'Portfolio Website',
  //   description: 'A personal portfolio website built with React, showcasing my projects and skills.',
  //   technologies: ['React', 'CSS', 'JavaScript'],
  //   link: '/projects/portfolio-website',
  // },
  // Add more projects as needed
];

const Projects = () => {
  return (
    <div className="projects">
      <h1 className="projects__title">Hobby Projects</h1>
      <p className="projects__subtitle">Here are some of the projects I’ve worked on:</p>

      <div className="projects__list">
        {projectData.map((project) => (
          <div key={project.id} className="projects__card">
            <h3 className="projects__card-title">{project.title}</h3>
            <p className="projects__card-description">{project.description}</p>
            <ul className="projects__technologies">
              {project.technologies.map((tech, index) => (
                <li key={index} className="projects__technology">{tech}</li>
              ))}
            </ul>
            {/* <a href={project.link} className="projects__link">
              View Project
            </a> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
