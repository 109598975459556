// DailyActivityBar.js
import React from "react";
import styled from "styled-components";

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  h4 {
    margin-bottom: 10px;
    font-size: 1.2em;
    text-align: center;
  }
`;

const ActivityBar = styled.div`
  display: flex;
  width: 100%;
  height: 40px; /* Increased height for better readability on mobile */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    height: 50px; /* Further increase height on small screens */
  }
`;

const CodingSection = styled.div`
  flex: 0.8; /* Represents 80% */
  background-color: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 0 5px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 2px;
  }

  @media (max-width: 500px) {
    font-size: 10px;
    content: "80%";
  }
`;

const MeetingSection = styled.div`
  flex: 0.2; /* Represents 20% */
  background-color: #2ecc71;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 0 5px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 2px;
  }

  @media (max-width: 500px) {
    font-size: 10px;
    content: "20%";
  }
`;

const DailyActivityBar = () => {
  return (
    <BarWrapper>
      <ActivityBar>
        <CodingSection>Coding & Problem Solving - 80%</CodingSection>
        <MeetingSection> Mentoring - 20%</MeetingSection>
      </ActivityBar>
    </BarWrapper>
  );
};

export default DailyActivityBar;
