// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import ProtectedPage from "./pages/ProtectedPage";
import LoginPage from "./pages/LoginPage"; // Create a simple login page
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./components/AuthContext";

const App = () => {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="/prtctd"
            element={
              <PrivateRoute>
                <ProtectedPage />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<LoginPage />} />
        </Route>
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;
