import React from 'react';
import './HighlightedName.css';
import logo from '../assets/DKLogo.png'; // Update this path based on where your image is located

const HighlightedName = () => {
  return (
    <div className="highlighted-name">
      <img src={logo} alt="Logo" className="highlighted-name__logo" />
      <span className="highlighted-name__text">
        Aditya <span className="highlighted-letter">D</span>haroor <span className="highlighted-letter">K</span>amsali
      </span>
      {/* <span className="highlighted-acronym">
        (<span className="highlighted-letter">D</span><span className="highlighted-letter">K</span>)
      </span> */}
    </div>
  );
};

export default HighlightedName;
