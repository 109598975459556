// Timeline.js
import React, { useState } from "react";
import styled from "styled-components";

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 50px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0px;
    margin-top: 20px;
  }
`;

const LineContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Six equal columns */
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
`;

const Line = styled.div`
  grid-column: 1 / -1;
  height: 3px;
  background-color: #ccc;
  position: absolute;
  width: 100%;
  top: 50%; /* Center line vertically in the container */
  transform: translateY(-50%);
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Dot = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) =>
    props.$highlighted || props.$isLastDot ? "#2ecc71" : "#3498db"};
  border-radius: 50%;
  cursor: pointer;
  border: 3px solid #ccc; /* Matches the line color */
  position: absolute;
  top: 50%; /* Aligns dot vertically in the middle of the line */
  transform: translateY(-50%); /* Centers the dot on the line */

  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
`;

const DotTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  max-width: 100px;
  margin-bottom: 30px; /* Increased further for more space above the dot */

  @media (max-width: 768px) {
    font-size: 10px;
    max-width: 80px;
    margin-bottom: 25px; /* Adjusted for smaller screens */
  }
`;

const DotYear = styled.div`
  font-size: 12px;
  text-align: center;
  max-width: 100px;
  margin-top: 30px; /* Increased further for more space below the dot */

  @media (max-width: 768px) {
    font-size: 10px;
    max-width: 80px;
    margin-top: 25px; /* Adjusted for smaller screens */
  }
`;

// Sample milestone data
const milestones = [
  { title: "Systems Engineer", year: "2012-TCS" },
  { title: "Masters in IT", year: "2015-MNSU" },
  { title: "Software Engineering - Intern", year: "2017-Starkey" },
  { title: "Software Engineer", year: "2019-Starkey" },
  { title: "Senior Software Engineer", year: "2021-Starkey" },
  { title: "Senior Software Engineer", year: "Current-Starkey" },
];

const Timeline = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const lastIndex = milestones.length - 1;

  return (
    <TimelineWrapper>
      <LineContainer>
        <Line />
        {milestones.map((milestone, i) => (
          <DotContainer key={i} onClick={() => setSelectedIndex(i)}>
            <DotTitle>{milestone.title}</DotTitle>
            <Dot
              $highlighted={i === selectedIndex}
              $isLastDot={i === lastIndex} // Keeps last dot highlighted
            />
            <DotYear>{milestone.year}</DotYear>
          </DotContainer>
        ))}
      </LineContainer>
    </TimelineWrapper>
  );
};

export default Timeline;
