import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Me</h1>
     

      <div className="contact-row">
        {/* Email Card */}
        <div className="contact-card">
          <h2 className="contact-section-title">Email</h2>
          <p className="contact-section-content">
            <a 
              href="mailto:aditya.dharoorkamsali@gmail.com" 
              className="contact-link"
            >
              aditya.dharoorkamsali@gmail.com
            </a>
          </p>
        </div>

        {/* Phone Card */}
        <div className="contact-card">
          <h2 className="contact-section-title">Phone</h2>
          <p className="contact-section-content">507-351-5819</p>
        </div>

        {/* LinkedIn Card */}
        <div className="contact-card">
          <h2 className="contact-section-title">LinkedIn</h2>
          <p className="contact-section-content">
            <a
              href="https://www.linkedin.com/in/adityadharoor/"
              target="_blank"
              rel="noreferrer"
              className="linkedin-link"
            >
              linkedin.com/in/adityadharoor
            </a>
          </p>
        </div>

        {/* Website Card */}
        <div className="contact-card">
          <h2 className="contact-section-title">Website</h2>
          <p className="contact-section-content">
            <a
              href="http://www.adityadharoor.com"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              www.adityadharoor.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
