import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1 className="about-title">About Me</h1>

      <p className="about-intro">
        Hello! I’m <strong>Aditya Dharoor Kamsali</strong>, a{" "}
        <strong>Senior Software Engineer</strong> specializing in{" "}
        <strong>Full Stack, Cloud, and AI</strong>. I’m passionate about tackling
        real-world problems with innovative technology solutions. Every day, I seek
        out new challenges that let me learn, grow, and push the boundaries of
        what’s possible in software engineering.
      </p>

      <p className="about-intro">
        I’ve had the privilege of working on projects that range from
        architecting high-traffic web applications to embedding machine
        learning algorithms into analytics platforms. My approach is hands-on
        and solution-oriented, and I love seeing the tangible impact that
        well-designed software can have on businesses and users around
        the world.
      </p>

      {/* Experience Section */}
      <h2 className="about-section-heading">Experience Highlights</h2>
      <div className="job-cards">
        {/* Senior Software Engineer Card */}
        <div className="job-card">
          <h3 className="job-title">
            Senior Software Engineer – Starkey Hearing (2021 – Present)
          </h3>
          <ul className="job-list">
            <li>
            Led the migration of on-premises websites to Azure Cloud using Azure Functions,
             Azure App Service, Azure Queues, Azure SQL Database and Azure DevOps Pipelines.
            </li>
            <li>
            Single-handedly built a RAG-based AI solution in Azure AI Studio,
             leveraging operator FAQ documents to power a web-accessible AI assistant that delivers grounded, real-time answers.
            </li>
            <li>
              Architected and deployed enterprise websites using React JS and C#,
              serving manufacturing floors across 24 countries with an average of
              10,000 hits per day.
            </li>
            <li>
              Co-Architected the next generation of software to manufacture and
              program hearing aids, increasing efficiency by 65%.
            </li>
            <li>
              Migrated legacy code from TFS to Azure Git; set up release pipelines
              in Azure DevOps; created installers using WiX and published NuGet
              packages.
            </li>
            <li>
              Developed real-time Power BI reports tracking hearing aid movement
              across global assembly lines, increasing overall efficiency by 12%.
            </li>
            <li>
              Embedded a Python-based ML algorithm into Power BI for predicting
              hearing aid return rates (95%+ accuracy).
            </li>
            <li>
              Hosted daily Scrum calls with an offshore team, served as the main
              point of contact for issue resolution, and mentored team members.
            </li>
          </ul>
        </div>

        {/* Software Engineer Card */}
        <div className="job-card">
          <h3 className="job-title">
            Software Engineer – Starkey Hearing (2017 – 2021)
          </h3>
          <ul className="job-list">
            <li>
              Developed web and desktop applications using C#, WPF, AngularJS,
              and React JS.
            </li>
            <li>
              Created AngularJS pages to retrieve data from SQL databases via
              C# web services.
            </li>
            <li>
              Implemented new features in a C# desktop application using WPF,
              XAML, and Microsoft SQL Server.
            </li>
          </ul>
        </div>

        {/* Systems Engineer Card */}
        <div className="job-card">
          <h3 className="job-title">
            Systems Engineer – Tata Consultancy Services (2012 – 2015)
          </h3>
          <ul className="job-list">
            <li>
              Built a proprietary UI automation tool that recorded and replayed
              mouse and keyboard interactions to automate tasks.
            </li>
            <li>
              Developed an automation language using Scriba and VC++.
            </li>
          </ul>
        </div>
      </div>

      {/* Skills Section */}
      <h2 className="about-section-heading">Skills</h2>
      <div className="skills-container">
        {/* AI Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">AI & Machine Learning</h3>
          <ul className="skill-items">
            <li>Generative AI</li>
            <li>OpenAI</li>
            <li>Gemini</li>
            <li>Azure Machine Learning</li>
          </ul>
        </div>

        {/* Front-End Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">Front-End Development</h3>
          <ul className="skill-items">
            <li>AngularJS</li>
            <li>React JS</li>
            <li>CSHTML</li>
            <li>CSS</li>
          </ul>
        </div>

        {/* Programming Languages Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">Programming Languages</h3>
          <ul className="skill-items">
            <li>C#</li>
            <li>JavaScript</li>
            <li>Python</li>
            <li>Windows Applications</li>
          </ul>
        </div>

        {/* Data / Analytics Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">Data & Analytics</h3>
          <ul className="skill-items">
            <li>Microsoft SQL Server</li>
            <li>SSIS, SSRS</li>
            <li>Power BI</li>
            <li>DAX</li>
          </ul>
        </div>

        {/* Cloud / Hosting Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">Cloud Platforms</h3>
          <ul className="skill-items">
            <li>Azure</li>
            <li>Google Cloud</li>
            <li>Firebase</li>
          </ul>
        </div>

        {/* Databases Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">Databases</h3>
          <ul className="skill-items">
            <li>Oracle</li>
            <li>MySQL</li>
            <li>MongoDB</li>
            <li>SQLite</li>
          </ul>
        </div>

        {/* DevOps / Tools Group */}
        <div className="skill-group-card">
          <h3 className="skill-group-title">DevOps & Tools</h3>
          <ul className="skill-items">
            <li>Azure DevOps, YAML, WiX</li>
            <li>Confluence, Jira, Azure</li>
            <li>Git, TFS, Bitbucket</li>
          </ul>
        </div>
      </div>

      {/* Education Section */}
      <h2 className="about-section-heading">Education</h2>
      <ul className="education-list">
        <li className="education-item">
          <p className="education-degree">
            Masters - Information Technology (2015-2017)
          </p>
          <p>Minnesota State University, Mankato - MN</p>
        </li>
        <li className="education-item">
          <p className="education-degree">
            Bachelors - Computer Science (2008-2012)
          </p>
          <p>Jawaharlal Nehru Technological University, Hyderabad</p>
        </li>
      </ul>

      {/* Publications Section */}
      <h2 className="about-section-heading">Publications</h2>
      <ul className="publication-list">
        <li className="publication-item">
          <p className="publication-title">
            Nursing Evaluation Using Smart Glass
          </p>
          <a 
            href="https://www.sigmarepository.org/convention/2017/presentations_2017/144/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: '#007bff' }}
          >
            SIGMA Repository Presentation, 2017
          </a>
        </li>
        <li className="publication-item">
          <p className="publication-title">Anti Land Grabbing Software</p>
          <a
            href="https://www.scribd.com/document/102024546/IJRDE-Anti-Land-Grabbing-Software"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: '#007bff' }}
          >
            Scribd Article
          </a>
        </li>
      </ul>

    </div>
  );
}

export default About;
