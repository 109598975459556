// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyUVye8NwN4f3Oi1T_7y0xG7i0aSIXMNc",
  authDomain: "aditya-demo-623cf.firebaseapp.com",
  projectId: "aditya-demo-623cf",
  storageBucket: "aditya-demo-623cf.appspot.com", // Fixed storageBucket URL
  messagingSenderId: "668135268680",
  appId: "1:668135268680:web:d9fc6a045dcfdbd2c9d37d",
  measurementId: "G-DDWJ6QXZ0M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export initialized services
export const db = getFirestore(app); // Firestore instance
export const storage = getStorage(app); // Storage instance
export const auth = getAuth(app); // Auth instance
